import styled from "@xstyled/styled-components";
import ReactPaginate from "react-paginate";

import { Button } from "@otta/design";

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ul {
    list-style: none;
  }

  ul > li {
    display: inline-block;
    margin: 0 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &.selected {
      font-weight: 700;
    }
  }
`;

const PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface IPageHeaderProps {
  currentPage: number;
  handlePageClick: ({ selected }: { selected: number }) => void;
  submitJobs: () => void;
  disableSubmit: boolean;
  pageCount: number;
}

export const PageHeader = ({
  currentPage,
  handlePageClick,
  submitJobs,
  disableSubmit,
  pageCount,
}: IPageHeaderProps) => {
  return (
    <PageHeaderWrapper>
      <PaginationWrapper>
        <ReactPaginate
          previousLabel={<Button level="secondary">Previous</Button>}
          nextLabel={<Button level="secondary">Next</Button>}
          pageCount={pageCount}
          forcePage={currentPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
        />
      </PaginationWrapper>

      <Button level="primary" onClick={submitJobs} disabled={disableSubmit}>
        Save changes
      </Button>
    </PageHeaderWrapper>
  );
};

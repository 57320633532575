import { DocumentNode, useMutation } from "@apollo/client";
import { FieldArray, Form, Formik } from "formik";
import { useCallback, useMemo } from "react";
import styled from "@xstyled/styled-components";

import {
  Button,
  Card,
  FieldWrapper,
  Fieldset,
  Input,
  SelectField,
  Spacing,
  Text,
  Tipbox,
} from "@otta/design";
import { palette } from "@otta/design-tokens";

type Item = {
  id: string;
  [key: string]: unknown;
};

type Key = string | number | symbol;

const Row = styled.div`
  justify-content: flex-end;
  display: flex;
  gap: 1rem;
`;

const ChildWrapper = styled.div`
  display: grid;
  gap: 1rem;
  padding: 1rem;
`;

const removeKeys = (obj: Item, omitKeys: Key[]): Item => {
  return Object.keys(obj).reduce((result, key) => {
    if (omitKeys.includes(key)) return result;
    const value = obj[key];
    if (typeof value === "string" && value.trim() === "") return result;
    if (Array.isArray(value)) {
      return {
        ...result,
        [key]: value.map((item: Item) =>
          removeKeys(
            item,
            // Keep the id field for relationships
            omitKeys.filter(k => k !== "id")
          )
        ),
      };
    }
    return { ...result, [key]: value };
  }, {} as Item);
};

/**
 * This evil component summons a nasty form for your graphql object from the ether
 * Just pretend you didn't see this, and please don't imitate it my goodness
 */
export function EditForm<A extends Item>({
  mutation,
  omitFields,
  numberFields,
  childrenFields,
  onClose,
  title,
  item,
}: {
  item: A;
  title?: string;
  mutation: DocumentNode;
  onClose?: () => void;
  numberFields?: (keyof A)[];
  omitFields?: (keyof A)[];
  childrenFields?: Record<string, string[]>;
}): React.ReactElement {
  const [update, { error, loading, data }] = useMutation(mutation);
  const numbers = numberFields ?? [];

  const omitKeys = useMemo(
    () => ["id", "__typename", ...(omitFields ?? [])] as (keyof A)[],
    [omitFields]
  );

  const onSubmit = useCallback(
    (id: string, input: A) => {
      update({ variables: { id, input: removeKeys(input, omitKeys) } });
    },
    [update, omitKeys]
  );

  const fields: (keyof A)[] = useMemo(
    () => Object.keys(item).filter(k => !omitKeys.includes(k)),
    [item, omitKeys]
  );

  return (
    <Card>
      <Spacing size={1}>
        {error && <Tipbox level="error">{error.message}</Tipbox>}
        {data && <Tipbox level="positive">Changes saved</Tipbox>}

        <Formik<A>
          onSubmit={values => onSubmit(item.id, values)}
          initialValues={item}
        >
          {({ handleChange, values, setFieldValue }) => (
            <Form>
              <Spacing size={1}>
                <Fieldset legend={title}>
                  {fields.map(name => (
                    <Field
                      setFieldValue={setFieldValue}
                      key={String(name)}
                      name={String(name)}
                      value={(values[name] as string) ?? ""}
                      handleChange={handleChange}
                      numbers={numbers}
                      childrenFields={childrenFields}
                    />
                  ))}
                </Fieldset>
                <Row>
                  <Button type="submit" level="primary" disabled={loading}>
                    Save
                  </Button>
                  {!!onClose && (
                    <Button
                      type="button"
                      level="secondary"
                      onClick={() => onClose()}
                    >
                      Close
                    </Button>
                  )}
                </Row>
              </Spacing>
            </Form>
          )}
        </Formik>
      </Spacing>
    </Card>
  );
}

const Field = ({
  value,
  name,
  label,
  handleChange,
  numbers,
  childrenFields,
  setFieldValue,
}: {
  value: unknown;
  name: string;
  label?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  numbers: Key[];
  childrenFields?: Record<string, string[]>;
  setFieldValue?: (field: string, value: string | null) => void;
}) => {
  const fields = useMemo(() => {
    if (Array.isArray(value) && childrenFields && childrenFields[name]) {
      return childrenFields[name];
    }
  }, [childrenFields, name, value]);

  if (Array.isArray(value)) {
    return (
      <FieldArray
        name={name}
        render={({ remove, push }) => (
          <div>
            {value.map((item, index) => {
              const childName = `${name}.${index}`;
              return (
                <ChildWrapper key={item.id || index}>
                  {fields?.map(field => (
                    <Field
                      key={field}
                      label={field}
                      name={`${childName}.${field}`}
                      value={item[field] ?? ""}
                      handleChange={handleChange}
                      numbers={numbers}
                    />
                  ))}

                  <div>
                    <Button
                      level="destructive"
                      size="small"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      Remove {name.slice(0, -1)}
                    </Button>
                  </div>
                </ChildWrapper>
              );
            })}
            <Button
              level="primary"
              size="small"
              type="button"
              onClick={() => {
                push(
                  fields?.reduce((acc, field) => ({ ...acc, [field]: "" }), {})
                );
              }}
            >
              Add {name.slice(0, -1)}
            </Button>
          </div>
        )}
      />
    );
  }

  return (
    <FieldWrapper
      label={label || name}
      fieldError={undefined}
      required={name !== "category"}
    >
      {({ field }) => (
        <>
          {name === "category" && setFieldValue ? (
            <CategoryDropDown
              onChange={v => setFieldValue("category", v)}
              value={(value as string) ?? null}
            />
          ) : (
            <Input
              name={name}
              onChange={handleChange}
              type={numbers.includes(name) ? "number" : "text"}
              value={(value as string) ?? ""}
              maxLength={name === "mission" ? 150 : undefined}
              {...field}
            />
          )}
          {name === "mission" && (
            <Text size={-1} color={palette.grayscale.shade600}>
              Mission has max character of 150
            </Text>
          )}
        </>
      )}
    </FieldWrapper>
  );
};

function CategoryDropDown({
  onChange,
  value,
}: {
  onChange(set: string | null): void;
  value: string | null;
}): React.ReactElement {
  const options = useMemo(
    () => [
      { label: "A day in the life of", value: "A day in the life of" },
      { label: "Job interview", value: "Job interview" },
      { label: "Yes we care", value: "Yes we care" },
      { label: "World of", value: "World of" },
      { label: "Open house", value: "Open house" },
    ],
    []
  );

  const val = useMemo(
    () => options.find(({ value: v }) => v === value),
    [options, value]
  );

  return (
    <SelectField
      isClearable
      options={options}
      onChange={v => {
        v?.value ? onChange(v.value) : onChange(null);
      }}
      value={val}
    />
  );
}

import { Fragment, useEffect, useState } from "react";
import styled from "@xstyled/styled-components";

import { TeamPrompts } from "./TeamPrompts";

import {
  CompanyTeam,
  CompanyTeamMember,
  JobCompanyTeamMember,
  JobCompanyTeamMemberPrompts,
  JobCompanyTeamMembersInput,
  TeamMemberRole,
} from "@toolbox/schema";
import { Checkbox } from "@toolbox/components/Input/Checkbox";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: sm;
`;

const TeamCheckbox = styled(Checkbox)`
  /* Prevents the whole line to be the label and thus clickable */
  display: inline-block;
`;

const Members = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  gap: xs sm;
  padding-left: sm;
`;

export type PartialJobCompanyTeamMember = Omit<
  JobCompanyTeamMember,
  "__typename" | "id" | "prompts"
> &
  Partial<Pick<JobCompanyTeamMember, "__typename" | "id">>;

type JobCompanyTeamMemberPromptsInput = Omit<
  JobCompanyTeamMemberPrompts,
  "__typename" | "id"
> &
  Partial<Pick<JobCompanyTeamMemberPrompts, "__typename">>;

const formatState = (
  teamId: string | undefined,
  members: JobCompanyTeamMember[]
) => ({
  currentTeamId: teamId,
  manager: members?.find(member => member.role === TeamMemberRole.Manager),
  directReports: members.filter(
    member => member.role === TeamMemberRole.Report
  ),
});

export const TeamSelect = ({
  teamId,
  teams,
  members,
  handleUpdate,
}: {
  teamId?: string;
  teams: CompanyTeam[];
  members: JobCompanyTeamMember[];
  handleUpdate: (
    teamId: string | undefined,
    teamMembers: JobCompanyTeamMembersInput[]
  ) => void;
}) => {
  const [{ currentTeamId, manager, directReports }, setState] = useState<{
    currentTeamId: string | undefined;
    manager: PartialJobCompanyTeamMember | undefined;
    directReports: PartialJobCompanyTeamMember[];
  }>(() => formatState(teamId, members));

  useEffect(() => {
    setState(formatState(teamId, members));
  }, [teamId, members]);

  const onChange = ({
    teamId,
    manager,
    directReports,
    prompts,
  }: {
    teamId: string | undefined;
    manager: PartialJobCompanyTeamMember | undefined;
    directReports: PartialJobCompanyTeamMember[];
    prompts?: JobCompanyTeamMemberPromptsInput[];
  }) => {
    setState({ currentTeamId: teamId, manager, directReports });

    handleUpdate(teamId, [
      ...(manager
        ? [
            {
              companyTeamMemberId: manager.member.id,
              role: manager.role,
              id: manager.id,
              jobCompanyTeamMemberPrompts: prompts,
            },
          ]
        : []),
      ...directReports.map(report => ({
        companyTeamMemberId: report.member.id,
        role: report.role,
        id: report.id,
      })),
    ]);
  };

  const handleChangeTeam = (teamId: string) => {
    const newTeamId = teamId === currentTeamId ? undefined : teamId;
    onChange({
      teamId: newTeamId,
      manager: undefined,
      directReports: [],
      prompts: [],
    });
  };

  const handleChangeManager = (member: CompanyTeamMember) => {
    const newManager =
      manager?.member.id === member.id
        ? undefined
        : {
            member,
            role: TeamMemberRole.Manager,
            jobCompanyTeamMemberPrompts: [],
          };
    onChange({
      manager: newManager,
      teamId: currentTeamId,
      directReports,
      prompts: manager?.jobCompanyTeamMemberPrompts ?? [],
    });
  };

  const handleChangeDirectReports = (member: CompanyTeamMember) => {
    const newDirectReports = directReports.some(
      report => report.member.id === member.id
    )
      ? directReports.filter(report => report.member.id !== member.id)
      : [
          ...directReports,
          {
            member,
            role: TeamMemberRole.Report,
            jobCompanyTeamMemberPrompts: [],
          },
        ];
    onChange({
      directReports: newDirectReports,
      teamId: currentTeamId,
      manager,
      prompts: manager?.jobCompanyTeamMemberPrompts ?? [],
    });
  };

  const handleChangePrompts = (prompts: JobCompanyTeamMemberPromptsInput[]) => {
    onChange({
      teamId,
      directReports,
      manager,
      prompts,
    });
  };

  return (
    <Wrapper>
      {teams.map(team => {
        const isSelected = team.id === currentTeamId;

        return (
          <div key={team.id}>
            <TeamCheckbox
              checked={isSelected}
              onChange={() => handleChangeTeam(team.id)}
              label={team.name}
            />
            {isSelected && (
              <div>
                Members:
                <Members>
                  {team.members.map(member => {
                    const isManager = manager?.member.id === member.id;
                    const isDirectReport = directReports.some(
                      report => report.member.id === member.id
                    );

                    const isManagerDisabled =
                      isDirectReport || (!!manager && !isManager);
                    const isDirectReportDisabled =
                      isManager ||
                      (directReports.length > 1 && !isDirectReport);

                    return (
                      <Fragment key={member.id}>
                        {member.name && `${member.name}: `}
                        {member.jobTitle}

                        <Checkbox
                          checked={isManager}
                          disabled={isManagerDisabled}
                          onChange={() => handleChangeManager(member)}
                          label="Manager"
                        />
                        <Checkbox
                          checked={isDirectReport}
                          disabled={isDirectReportDisabled}
                          onChange={() => handleChangeDirectReports(member)}
                          label="Direct report"
                        />
                      </Fragment>
                    );
                  })}
                </Members>
                {manager && (
                  <TeamPrompts
                    manager={manager}
                    onChange={handleChangePrompts}
                  />
                )}
              </div>
            )}
          </div>
        );
      })}
    </Wrapper>
  );
};

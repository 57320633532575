import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";
import { Button, Text, InputField } from "@otta/design";
import { Icon } from "@otta/icons";

const SmallButton = styled(Button)`
  padding: 0.5rem 1.5rem;
  font-weight: bold;
  display: flex;
  gap: 0.5rem;
`;

const IconButton = styled(SmallButton)`
  padding-left: 1rem;
`;

const TitleText = styled(Text)`
  line-height: 1;
`;

const TitleRow = styled.div`
  align-items: flex-end;
  display: flex;
  gap: 0.5rem;
`;

const HeaderRow = styled.div`
  align-items: center;
  display: flex;
  gap: 1.5rem;
`;

const Spacer = styled.span`
  margin-right: auto;
`;

function Title({
  totalCount,
  selectedCount,
}: {
  totalCount: number;
  selectedCount: number;
}): React.ReactElement {
  return (
    <TitleRow>
      <TitleText as="h2" size={2} bold>
        Move companies to Starter
      </TitleText>
      <Text color={palette.grayscale.shade600} size={0}>
        {selectedCount}/{totalCount} selected
      </Text>
    </TitleRow>
  );
}

export function Header({
  selectedCount,
  shownCount,
  totalCount,
  onSearch,
  onExport,
  onMove,
  onAdd,
}: {
  selectedCount: number;
  shownCount: number;
  totalCount: number;
  onSearch?: React.ChangeEventHandler<HTMLInputElement>;
  onExport?: React.MouseEventHandler<HTMLButtonElement>;
  onMove?: React.MouseEventHandler<HTMLButtonElement>;
  onAdd?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <HeaderRow data-testid="header">
      <Title selectedCount={selectedCount} totalCount={totalCount} />
      <SmallButton
        level="primary"
        disabled={selectedCount === 0}
        onClick={onMove}
      >
        Move to Starter
      </SmallButton>
      <Spacer />
      <InputField
        type="search"
        placeholder="Search"
        name="search"
        onChange={onSearch}
      />
      <IconButton
        level="secondary"
        onClick={onExport}
        disabled={shownCount === 0}
      >
        <Icon icon="download" />
        Export
      </IconButton>
      <IconButton level="secondary" onClick={onAdd}>
        <Icon icon="plus" />
        Add company to Starter
      </IconButton>
    </HeaderRow>
  );
}

import styled from "@xstyled/styled-components";

import { modularScale, palette, pxToRem } from "@otta/design-tokens";
import { Text } from "@otta/design";

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const TR = styled.tr<{ selected?: boolean }>`
  ${p => (p.selected ? `background: ${palette.extended.yellow.shade100};` : "")}
  border-bottom: ${pxToRem(1)} solid ${palette.grayscale.shade200};
  line-height: ${pxToRem(44)};
  text-align: left;
`;

export const TH = styled.th`
  font-size: ${modularScale(0)};
  font-weight: bold;
`;

export function YesNo({ value }: { value: boolean }): React.ReactElement {
  return value ? (
    <Text color={palette.extended.green.shade600}>Yes</Text>
  ) : (
    <Text color={palette.extended.red.shade600}>No</Text>
  );
}

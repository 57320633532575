import styled from "@xstyled/styled-components";
import { useParams } from "react-router-dom";

import { Assets } from "./EmployerBranding/Assets";
import { Offices } from "./EmployerBranding/Offices";
import { Teams } from "./EmployerBranding/Teams";
import { PeopleBreakdown } from "./EmployerBranding/PeopleBreakdown";

import { Heading, Spacing, Tipbox } from "@otta/design";
import { useQuery } from "@toolbox/apollo";
import { CompanyDocument } from "@toolbox/schema";
import { Loading } from "@otta/shared-components";
import { pxToRem } from "@otta/design-tokens";

const Page = styled.div`
  max-width: ${pxToRem(960)};
  padding: 2rem;
`;

export function EmployerBranding(): React.ReactElement {
  const { companyId } = useParams();

  const { data, loading } = useQuery(CompanyDocument, {
    variables: { id: companyId || "" },
  });

  if (loading) {
    return <Loading />;
  }

  if (!companyId || !data?.company) {
    return <Tipbox level="error">Missing company ID</Tipbox>;
  }

  return (
    <Page>
      <Spacing size={4}>
        <Heading size={4}>Employer Branding</Heading>
        <Assets companyId={data.company.externalId} />
        <Teams companyId={data.company.id} />
        <Offices companyId={data.company.id} />
        <PeopleBreakdown companyId={data.company.id} />
      </Spacing>
    </Page>
  );
}

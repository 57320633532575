import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";
import { useCallback, useMemo } from "react";

import { Modal } from "./lib/Modal";
import { TH, TR, Table, YesNo } from "./lib/Table";

import {
  AddCompaniesToStarterDocument,
  AddCompaniesToStarterMutation,
  PotentialStarterCompaniesQuery,
} from "@toolbox/schema";
import { pxToRem } from "@otta/design-tokens";
import { Button, Text, Tipbox } from "@otta/design";
import { Loading } from "@otta/shared-components";

const ModalContent = styled.div`
  flex-direction: column;
  max-height: 100%;
  display: flex;
  width: 100%;
  gap: 1.5rem;
`;

const TableContainer = styled.div`
  overflow-y: auto;
`;

const StyledModal = styled(Modal)`
  &[open] {
    display: flex;
    flex: 1 1 0;
  }
  min-width: ${pxToRem(800)};
  border-radius: 1rem;
  padding: 1.5rem;
  margin: auto;
`;

const Buttons = styled.div`
  justify-content: center;
  flex-direction: row;
  display: flex;
  gap: 1rem;
`;

const FlexButton = styled(Button)`
  flex: 1 1 0;
`;

const SmallFlexButton = styled(Button)`
  align-self: center;
  flex: 0 1 0;
`;

function BulkMovePreview({
  companies,
  onConfirm,
  onClose,
}: {
  companies: PotentialStarterCompaniesQuery["potentialCoreCompanies"];
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
}): React.ReactElement {
  return (
    <>
      <Text as={"h2"} size={2} bold align="center">
        Confirm you want to move {companies.length}{" "}
        {companies.length === 1 ? "company" : "companies"} to Starter
      </Text>
      <TableContainer>
        <Table>
          <thead>
            <TR>
              <TH>Company name</TH>
              <TH># live jobs</TH>
              <TH>Reason for moving</TH>
              <TH>Free job slots?</TH>
              <TH>Previously pro?</TH>
            </TR>
          </thead>
          <tbody>
            {companies.map(({ company, hasFreeJobSlots, reason }, index) => (
              <TR key={index}>
                <td>{company.name}</td>
                <td>{company.numberLiveJobs}</td>
                <td>{reason}</td>
                <td>
                  {" "}
                  <YesNo value={hasFreeJobSlots} />
                </td>
                <td>
                  <YesNo value={company.wasPro} />
                </td>
              </TR>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <Buttons>
        <FlexButton level="secondary" onClick={onClose}>
          Cancel
        </FlexButton>
        <FlexButton level="primary" onClick={onConfirm} data-testid="move">
          Move to Starter
        </FlexButton>
      </Buttons>
    </>
  );
}

function BulkMoveResult({
  onClose,
  data,
}: {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  data: AddCompaniesToStarterMutation;
}): React.ReactElement {
  const skipped = useMemo(
    () => data.bulkEnrolIntoJobSlots.filter(c => !c.jobSlotData),
    [data]
  );

  const enrolled = useMemo(
    () => data.bulkEnrolIntoJobSlots.filter(c => c.jobSlotData),
    [data]
  );

  return (
    <>
      <Text
        as={"h2"}
        size={2}
        bold
        align="center"
        data-testid="moved-to-starter"
      >
        {enrolled.length} {enrolled.length === 1 ? "company" : "companies"}{" "}
        moved to Starter
      </Text>
      {!!skipped.length && (
        <>
          <Tipbox level="warning">
            Some companies were skipped as they're no longer eligible
          </Tipbox>
          <TableContainer data-testid="skipped">
            <Table>
              <thead>
                <TR>
                  <TH>Company ID</TH>
                  <TH>Company name</TH>
                </TR>
              </thead>
              <tbody>
                {skipped.map(({ id, name }, index) => (
                  <TR key={index}>
                    <td>{id}</td>
                    <td>{name}</td>
                  </TR>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        </>
      )}

      <Buttons>
        <SmallFlexButton level="primary" onClick={onClose}>
          Close
        </SmallFlexButton>
      </Buttons>
    </>
  );
}

export function BulkMove({
  companies,
  onClose,
  open,
}: {
  companies: PotentialStarterCompaniesQuery["potentialCoreCompanies"];
  onClose: () => void;
  open: boolean;
}): React.ReactElement {
  const companyIds = useMemo(
    () => companies.map(({ id }: { id: string }) => id),
    [companies]
  );
  const [submit, { data, loading, reset }] = useMutation(
    AddCompaniesToStarterDocument,
    { variables: { companyIds } }
  );

  const close: () => void = useCallback(() => {
    onClose();
    reset();
  }, [reset, onClose]);

  return (
    <StyledModal open={open} data-testid="bulk-move">
      <ModalContent>
        {loading ? (
          <Loading />
        ) : data ? (
          <BulkMoveResult data={data} onClose={() => close()} />
        ) : (
          <BulkMovePreview
            companies={companies}
            onConfirm={() => submit()}
            onClose={() => close()}
          />
        )}
      </ModalContent>
    </StyledModal>
  );
}

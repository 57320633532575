import { Routes, Route, useLocation } from "react-router-dom";
import styled from "@xstyled/styled-components";
import { useMemo, useState } from "react";
import { startOfDay } from "date-fns";

import { BulkPreApproval } from "./PreApproval";
import FiltersProvider from "./FiltersProvider";
import { JobsPipeline } from "./JobsPipeline";
import { JobsList } from "./JobsList";
import { JobsIndex } from "./JobsIndex";

import { Text, Filter, Spacing, Button } from "@otta/design";
import { modularScale, pxToRem, palette } from "@otta/design-tokens";
import { CurrentRoleType, useUser } from "@toolbox/utils/user";
import { getAllowedJobWorkflowStatuses } from "@toolbox/utils/workflowStatus";
import {
  ILocationPreference,
  LocationPreferencesField,
} from "@toolbox/components/Field/LocationPreferencesField";
import { Link } from "@toolbox/components/Link";
import {
  JobFunctionsSubFunctionsDocument,
  JobsProcessedDocument,
  JobWorkflowStatus,
} from "@toolbox/schema";
import { useQuery } from "@toolbox/apollo";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: md;
  }
`;

const FilterButton = styled.button`
  font-family: inherit;
  position: relative;
  display: inline-block;
  padding: 6 15;
  margin-bottom: sm;
  border-radius: ${pxToRem(12)};
  border: 1px solid ${palette.grayscale.shade200};
  background-color: white;
  cursor: pointer;
`;

const ResetButton = styled(Button)`
  position: absolute;
  top: ${pxToRem(7)};
  right: ${pxToRem(7)};
  border: none;
  cursor: pointer;
  color: red-600;
  background-color: transparent;
  font-weight: bold;
`;

const SelectedContainer = styled.span`
  display: inline-block;
  background-color: red-600;
  color: white;
  border-radius: 50%;
  height: ${modularScale()};
  width: ${modularScale()};
  line-height: ${modularScale()};
  margin-left: xs;
  font-size: ${pxToRem(10)};
`;

const PointerArrow = styled.div<{ color?: string }>`
  z-index: 1;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  bottom: ${pxToRem(-10)};
  left: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left: ${pxToRem(10)} solid transparent;
  border-right: ${pxToRem(10)} solid transparent;
  border-bottom: ${pxToRem(10)} solid
    ${({ color }) => (color ? color : palette.brand.white)};
`;

const FilterButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const FieldsWrapper = styled.div`
  position: absolute;
  right: 0;
  width: max-content;
  top: 100%;
  z-index: 7;
  background-color: white;
  padding: 1.2rem;
  max-width: ${pxToRem(500)};
  border-radius: 1.2rem;
`;

const TextAndSelectedContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default function Jobs(): React.ReactElement | null {
  const { user, role, permissions } = useUser();
  const { data: functionsData } = useQuery(JobFunctionsSubFunctionsDocument);
  const { data: jobsProcessedData } = useQuery(JobsProcessedDocument, {
    variables: {
      since: startOfDay(new Date()).toISOString(),
    },
  });

  const [filtersSubFunction, setFiltersSubFunction] = useState({});
  const [filtersLocation, setFiltersLocation] = useState<ILocationPreference[]>(
    []
  );

  const [show, setShow] = useState(false);

  const filterOption = useMemo(
    () =>
      (functionsData?.jobFunctions ?? []).map(jobFunction => ({
        value: jobFunction.id,
        label: jobFunction.value,
        subOptions: jobFunction.subFunctions.map(subFunction => ({
          label: subFunction.value,
          value: subFunction.id,
        })),
      })),
    [functionsData]
  );

  const location = useLocation();

  if (!user) {
    return null;
  }

  const jobsRole = jobsProcessedData?.me?.__typename;
  let jobsProcessed = null;

  // GraphQL is being annoying here - jobsProcessed now only exists for admins and operators,
  // But makes me check the role first
  if (
    jobsRole === CurrentRoleType.Admin ||
    jobsRole === CurrentRoleType.InternalOperator
  ) {
    jobsProcessed = jobsProcessedData?.me?.jobsProcessed;
  }

  const statuses = getAllowedJobWorkflowStatuses(role, permissions);

  const commonProps = {
    permissions,
    role,
  };

  return (
    <Wrapper>
      <div
        style={{
          padding: `${modularScale()} 0 ${modularScale(-5)} 0`,
        }}
      >
        <Spacing size={-2}>
          {typeof jobsProcessed === "number" && (
            <Text>Jobs Processed Today: {jobsProcessed}</Text>
          )}
          <TopBar>
            <Links>
              {statuses.has(JobWorkflowStatus.PreApproval) && (
                <Link to="pre-approval">
                  <Text bold>Pre-Approval</Text>
                </Link>
              )}
              {role === CurrentRoleType.Admin && (
                <Link to="bulk-pre-approval">
                  <Text bold>Bulk Pre-Approval</Text>
                </Link>
              )}
              {statuses.has(JobWorkflowStatus.QueuedExternal) && (
                <Link to="queued-external">
                  <Text bold>Queued (External)</Text>
                </Link>
              )}
              {statuses.has(JobWorkflowStatus.QaExternal) && (
                <Link to="qa-external">
                  <Text bold>QA (External)</Text>
                </Link>
              )}
              {statuses.has(JobWorkflowStatus.QaInternal) && (
                <Link to="qa-internal">
                  <Text bold>QA (Internal)</Text>
                </Link>
              )}
              {statuses.has(JobWorkflowStatus.Exception) && (
                <Link to="exceptions">
                  <Text bold>Exceptions</Text>
                </Link>
              )}
              {statuses.has(JobWorkflowStatus.Disapproved) && (
                <Link to="disapproved">
                  <Text bold>Disapproved</Text>
                </Link>
              )}
              {statuses.has(JobWorkflowStatus.Completed) && (
                <Link to="completed">
                  <Text bold>Completed</Text>
                </Link>
              )}
              {statuses.has(JobWorkflowStatus.Changed) && (
                <Link to="changed">
                  <Text bold>Changed</Text>
                </Link>
              )}
            </Links>
            {!location.pathname.includes("bulk-pre-approval") && (
              <FilterButtonWrapper>
                <FilterButton onClick={() => setShow(!show)}>
                  {show && <PointerArrow />}
                  <TextAndSelectedContainer>
                    Filters
                    {(Object.keys(filtersSubFunction).length !== 0 ||
                      Object.keys(filtersLocation).length !== 0) && (
                      <SelectedContainer>
                        {Object.keys(filtersSubFunction).length +
                          Object.keys(filtersLocation).length}
                      </SelectedContainer>
                    )}
                  </TextAndSelectedContainer>
                </FilterButton>
                {show && (
                  <FieldsWrapper>
                    {(Object.keys(filtersSubFunction).length !== 0 ||
                      Object.keys(filtersLocation).length !== 0) && (
                      <ResetButton
                        level="tertiary"
                        size="small"
                        onClick={() => {
                          setFiltersSubFunction({});
                          setFiltersLocation([]);
                        }}
                      >
                        Reset filters
                      </ResetButton>
                    )}
                    <Spacing>
                      <LocationPreferencesField
                        label="Location"
                        locationPreferences={filtersLocation}
                        handleUpdate={newLocationPreferences => {
                          setFiltersLocation(newLocationPreferences);
                        }}
                      />

                      <Filter
                        title="Functions"
                        options={filterOption}
                        selectedOptions={filtersSubFunction}
                        onChange={setFiltersSubFunction}
                      />
                    </Spacing>
                  </FieldsWrapper>
                )}
              </FilterButtonWrapper>
            )}
          </TopBar>
        </Spacing>
      </div>
      <FiltersProvider
        value={{
          subFunction: filtersSubFunction,
          location: filtersLocation,
        }}
      >
        <Routes>
          {statuses.has(JobWorkflowStatus.PreApproval) &&
            role === CurrentRoleType.Admin && (
              <Route path="bulk-pre-approval/*" element={<JobsList />} />
            )}
          {statuses.has(JobWorkflowStatus.PreApproval) && (
            <Route path="pre-approval/*" element={<BulkPreApproval />} />
          )}
          {statuses.has(JobWorkflowStatus.QueuedExternal) && (
            <Route
              path="queued-external/*"
              element={
                role === CurrentRoleType.InternalOperator ? (
                  <JobsPipeline
                    {...commonProps}
                    workflowStatus={JobWorkflowStatus.QueuedExternal}
                  />
                ) : (
                  <JobsIndex
                    {...commonProps}
                    perPage={10}
                    workflowStatus={JobWorkflowStatus.QueuedExternal}
                  />
                )
              }
            />
          )}
          {statuses.has(JobWorkflowStatus.QaExternal) && (
            <Route
              path="qa-external/*"
              element={
                role === CurrentRoleType.InternalOperator ? (
                  <JobsPipeline
                    {...commonProps}
                    workflowStatus={JobWorkflowStatus.QaExternal}
                  />
                ) : (
                  <JobsIndex
                    {...commonProps}
                    perPage={10}
                    workflowStatus={JobWorkflowStatus.QaExternal}
                  />
                )
              }
            />
          )}

          {statuses.has(JobWorkflowStatus.QaInternal) && (
            <Route
              path="qa-internal/*"
              element={
                <JobsIndex
                  {...commonProps}
                  perPage={10}
                  workflowStatus={JobWorkflowStatus.QaInternal}
                />
              }
            />
          )}
          {statuses.has(JobWorkflowStatus.Exception) && (
            <Route
              path="exceptions/*"
              element={
                <JobsIndex
                  {...commonProps}
                  perPage={10}
                  workflowStatus={JobWorkflowStatus.Exception}
                />
              }
            />
          )}
          {statuses.has(JobWorkflowStatus.Disapproved) && (
            <Route
              path="disapproved/*"
              element={
                <JobsIndex
                  {...commonProps}
                  perPage={10}
                  workflowStatus={JobWorkflowStatus.Disapproved}
                />
              }
            />
          )}
          {statuses.has(JobWorkflowStatus.Completed) && (
            <Route
              path="completed/*"
              element={
                <JobsIndex
                  {...commonProps}
                  perPage={10}
                  workflowStatus={JobWorkflowStatus.Completed}
                />
              }
            />
          )}
          {statuses.has(JobWorkflowStatus.Changed) && (
            <Route
              path="changed/*"
              element={
                role === CurrentRoleType.InternalOperator ? (
                  <JobsPipeline
                    {...commonProps}
                    workflowStatus={JobWorkflowStatus.Changed}
                  />
                ) : (
                  <JobsIndex
                    {...commonProps}
                    perPage={10}
                    workflowStatus={JobWorkflowStatus.Changed}
                  />
                )
              }
            />
          )}
        </Routes>
      </FiltersProvider>
    </Wrapper>
  );
}

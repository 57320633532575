import { useMutation } from "@apollo/client";
import { Field, Form as FinalForm } from "react-final-form";
import styled from "@xstyled/styled-components";

import { Button, InputField, Text } from "@otta/design";
import { modularScale, palette, pxToRem } from "@otta/design-tokens";
import { required } from "@otta/shared-components";
import { CreateJobOfferDocument } from "@toolbox/schema";
import { handleMutationError } from "@toolbox/utils/error";

const PageWrapper = styled.div`
  margin: xl 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  max-width: ${pxToRem(570)};
  min-height: ${pxToRem(88)};
`;

const SubmissionText = styled(Text)`
  margin: 0;
  position: relative;
  bottom: ${modularScale(1)};
`;

export function JobOffers(): React.ReactElement {
  const [createMutation, { data, loading }] = useMutation(
    CreateJobOfferDocument,
    {
      onError: handleMutationError,
    }
  );

  const onSubmit = ({ jobId, userId }: { jobId: string; userId: string }) => {
    createMutation({
      variables: { jobId, userId, input: { accepted: true } },
    });
  };

  return (
    <>
      <hr />
      <PageWrapper>
        <FinalForm onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} data-testid="login-form">
              <Row>
                <Field name="jobId" validate={required}>
                  {({ input, meta }) => (
                    <InputField
                      {...input}
                      label="Job ID"
                      type="text"
                      error={meta.touched && meta.error}
                      data-testid="job-id-input-field"
                    />
                  )}
                </Field>
                <Field name="userId" validate={required}>
                  {({ input, meta }) => (
                    <InputField
                      {...input}
                      label="User ID"
                      type="text"
                      error={meta.touched && meta.error}
                      data-testid="user-id-input-field"
                    />
                  )}
                </Field>
                <Button
                  level="primary"
                  type="submit"
                  style={{ height: "fit-content", alignSelf: "center" }}
                  disabled={loading}
                >
                  Submit
                </Button>
              </Row>
            </form>
          )}
        </FinalForm>
        {!loading && data?.updateJobOffer?.id && (
          <SubmissionText
            align="left"
            size={-1}
            style={{
              color: palette.extended.green.shade600,
            }}
          >
            Job offer inserted!
          </SubmissionText>
        )}
      </PageWrapper>
    </>
  );
}

import styled from "@xstyled/styled-components";
import { useCallback, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";

import { EditForm } from "./EditForm";
import { LinkForm } from "./LinkForm";

import { Button, Card, Heading, Input, Spacing, Text } from "@otta/design";
import {
  CompanyBrandAssetsDocument,
  UploadCompanyBrandAssetDocument,
  UpdateCompanyBrandAssetDocument,
  CompanyBrandAssetsQuery,
  BrandAssetType,
  CreateCompanyBrandAssetDocument,
} from "@toolbox/schema";
import { UploadField } from "@toolbox/components/Field/UploadField";
import { useQuery } from "@toolbox/apollo";
import { Loading } from "@otta/shared-components";

const AssetButton = styled.button<{ current?: boolean }>`
  all: unset;
  flex-grow: 1;
  border: none;
  background: transparent;
  ${p => (p.current ? "border: 0.25rem solid yellow" : "")};
  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Placeholder = styled.div`
  background-color: blue-400;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
`;

const Images = styled.div`
  gap: sm;
  flex-wrap: wrap;
  display: flex;
  width: 100%;
`;

const AddFormCard = styled(Card)`
  display: flex;
  gap: 1rem;
  & > button {
    flex-shrink: 0;
  }
`;

function AddForm({ companyId }: { companyId: string }) {
  const [source, setSource] = useState<string | null>(null);
  const [run, { loading }] = useMutation(CreateCompanyBrandAssetDocument);

  const onClick = useCallback(async () => {
    await run({
      variables: {
        id: companyId,
        input: {
          type: BrandAssetType.Vimeo,
          source,
        },
      },
    });
    setSource(null);
  }, [source, run, setSource, companyId]);

  return (
    <AddFormCard>
      <Input
        type="text"
        value={source ?? ""}
        onChange={e => setSource(e.target.value)}
        placeholder="Vimeo ID"
      />
      <Button level="primary" onClick={() => onClick()} disabled={loading}>
        Add video
      </Button>
    </AddFormCard>
  );
}

type Company = NonNullable<CompanyBrandAssetsQuery["company"]>;

export function Assets({
  companyId,
}: {
  companyId: string;
}): React.ReactElement {
  const [assetId, setAssetId] = useState<string | null>();
  const { data, loading } = useQuery(CompanyBrandAssetsDocument, {
    variables: { id: companyId },
  });
  const asset = useMemo(
    () => data?.company?.brandAssets.find(i => i.id === assetId),
    [data, assetId]
  );

  if (loading || !data?.company) {
    return <Loading />;
  }

  return (
    <Spacing size={1}>
      <Heading size={3}>Assets</Heading>
      <Card>
        <UploadField
          id={companyId}
          label="Add an image"
          accept={["image/jpeg"]}
          mutation={UploadCompanyBrandAssetDocument}
          display={() => null}
          value={null}
        />
      </Card>
      <AddForm companyId={data.company.id} />
      <Card>
        <Images>
          {data?.company?.brandAssets.map((ba, i) => (
            <AssetButton
              onClick={() => setAssetId(ba.id)}
              current={assetId === ba.id}
              key={i}
            >
              {ba.type === BrandAssetType.Image ? (
                <img
                  srcSet={ba.sourceSet ?? undefined}
                  src={ba.source ?? undefined}
                  alt={ba.alt ?? undefined}
                />
              ) : (
                <Placeholder>
                  <Text size={9}>
                    <span role="img" aria-label="video">
                      🎬
                    </span>
                  </Text>
                  <Text size={4}>{ba.source}</Text>
                </Placeholder>
              )}
            </AssetButton>
          ))}
        </Images>
      </Card>
      {!!asset && (
        <Spacing size={1}>
          <EditForm
            item={asset}
            key={asset.id}
            onClose={() => setAssetId(null)}
            mutation={UpdateCompanyBrandAssetDocument}
            omitFields={["source", "links", "sourceSet", "type"]}
          />
          {asset?.links.map(link => (
            <LinkForm
              parent={link}
              company={data.company as Company}
              placements={data.allBrandAssetPlacements ?? []}
              regions={data.regions}
              key={link.id}
            />
          ))}
          <LinkForm
            key={`new-asset-link-${asset.id}`}
            placements={data.allBrandAssetPlacements ?? []}
            company={data.company as Company}
            regions={data.regions}
            parent={asset}
          />
        </Spacing>
      )}
    </Spacing>
  );
}

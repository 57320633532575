import styled from "@xstyled/styled-components";
import * as Yup from "yup";
import { Formik, Field, Form, FieldArray } from "formik";
import { useMemo, useState } from "react";

import { PartialJobCompanyTeamMember } from "./TeamSelect";

import { Button, ErrorText, TextareaField, Text } from "@otta/design";
import { modularScale, palette } from "@otta/design-tokens";
import { PlusIcon } from "@toolbox/components/Icons/PlusIcon";
import { JobCompanyTeamMemberPrompts } from "@toolbox/schema";

const GreyButton = styled(Button)`
  flex-grow: 0;
  font-size: ${modularScale(-2)};
  font-weight: normal;
  background: ${palette.grayscale.shade400};
  &:hover {
    background: ${palette.grayscale.shade200};
  }
  &:focus {
    background: ${palette.grayscale.shade200};
  }
`;

const IconContainer = styled.div`
  display: flex;
  margin-right: xs;
  align-items: center;
`;

const StyledTextareaField = styled(TextareaField)`
  resize: vertical;
`;

const validationSchema = Yup.object().shape({
  prompts: Yup.array().of(
    Yup.object().shape({
      question: Yup.string().required(),
      answer: Yup.string().required(),
    })
  ),
});

type JobCompanyTeamMemberPromptsInput = Omit<
  JobCompanyTeamMemberPrompts,
  "__typename" | "id"
>;

export const TeamPrompts = ({
  manager,
  onChange,
}: {
  manager: PartialJobCompanyTeamMember;
  onChange: (prompts: JobCompanyTeamMemberPromptsInput[]) => void;
}) => {
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const prompts = useMemo(() => {
    return manager.jobCompanyTeamMemberPrompts.map(({ answer, question }) => ({
      answer,
      question,
    }));
  }, [manager.jobCompanyTeamMemberPrompts]);

  return (
    <Formik
      enableReinitialize
      initialValues={{ prompts: prompts }}
      validationSchema={validationSchema}
      onSubmit={(values: { prompts: JobCompanyTeamMemberPromptsInput[] }) => {
        onChange(values.prompts);
        setEditingIndex(null);
      }}
    >
      {({ values, handleSubmit, errors, touched }) => (
        <Form>
          <FieldArray name="prompts">
            {({ push }) => (
              <div>
                {values.prompts.map((prompt, index) => (
                  <div key={index}>
                    <div>
                      <label htmlFor={`prompts[${index}].question`}>
                        Question
                      </label>
                      <Field
                        as={StyledTextareaField}
                        value={prompt.question}
                        name={`prompts[${index}].question`}
                        disabled={editingIndex !== index}
                      />
                    </div>
                    <div>
                      <label htmlFor={`prompts[${index}].answer`}>Answer</label>
                      <Text>255 characters or less</Text>
                      <Field
                        as={StyledTextareaField}
                        value={prompt.answer}
                        name={`prompts[${index}].answer`}
                        disabled={editingIndex !== index}
                      />
                    </div>
                    <GreyButton
                      level="secondary"
                      size="small"
                      type="button"
                      tabIndex={0}
                      onClick={() =>
                        editingIndex === index
                          ? handleSubmit()
                          : setEditingIndex(index)
                      }
                    >
                      {editingIndex === index ? "Save" : "Edit"}
                    </GreyButton>
                  </div>
                ))}
                {touched.prompts && errors.prompts && (
                  <ErrorText style={{ display: "block" }}>
                    Include both a question and answer. Answer must be 255
                    characters or less.
                  </ErrorText>
                )}
                <GreyButton
                  level="secondary"
                  size="small"
                  disabled={editingIndex !== null}
                  tabIndex={0}
                  onClick={() => {
                    push({ question: "", answer: "" });
                    setEditingIndex(values.prompts.length);
                  }}
                >
                  <IconContainer>
                    <PlusIcon />
                  </IconContainer>
                  {`${prompts.length > 0 ? "Add another" : "Add"} prompt for ${
                    manager.member.name
                  }`}
                </GreyButton>
              </div>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
};

import { sub, format, parseISO } from "date-fns";
import styled from "@xstyled/styled-components";
import { useParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { useMutation } from "@apollo/client";

import { Button, Text, Spacing, SelectField } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { useQuery } from "@toolbox/apollo";
import {
  CompanyJobApplicationsDocument,
  UpdateJobApplicationExternalRespondedDocument,
} from "@toolbox/schema";
import { Link } from "@toolbox/components/Link";
import {
  Table,
  TableHeader,
  TableRow,
  TableCell,
} from "@toolbox/components/Table";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  padding: lg;
  padding-top: 0;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OPTIONS = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export function JobApplications(): React.ReactElement {
  const { companyId } = useParams();

  const defaultVariables = useMemo(
    () => ({
      id: companyId as string,
      limit: 50,
      offset: 0,
      appliedAtGt: sub(new Date(), { days: 90 }).toISOString(),
      appliedAtLt: sub(new Date(), { days: 14 }).toISOString(),
    }),
    [companyId]
  );

  const { data, fetchMore } = useQuery(CompanyJobApplicationsDocument, {
    variables: defaultVariables,
  });

  const [updateJobApplication, { loading: mutationLoading }] = useMutation(
    UpdateJobApplicationExternalRespondedDocument
  );

  const company = data?.company;

  const handleChange = useCallback(
    (
      externalId: string,
      option: {
        label: string;
        value: boolean;
      } | null
    ) => {
      if (option) {
        updateJobApplication({
          variables: { externalId, externalResponded: option.value },
        });
      }
    },
    [updateJobApplication]
  );

  if (!company) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <Spacing>
        <FlexWrapper>
          <Text bold size={1}>
            {company.name}
          </Text>
          <Text bold size={1}>
            Response rate: {company.responseRate}
          </Text>
        </FlexWrapper>
        <Spacing size={-2}>
          <Table>
            <thead>
              <TableRow>
                <TableHeader />
                <TableHeader>Title (subtitle)</TableHeader>
                <TableHeader>Application ID</TableHeader>
                <TableHeader>Candidate ID</TableHeader>
                <TableHeader>Candidate name</TableHeader>
                <TableHeader>Applied at (UTC)</TableHeader>
                <TableHeader>External responded</TableHeader>
              </TableRow>
            </thead>
            <tbody>
              {company.jobApplications.map(
                (
                  {
                    id,
                    appliedAt,
                    job,
                    externalId,
                    candidate,
                    externalResponded,
                  },
                  index
                ) => (
                  <TableRow key={id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <Link to={`https://app.otta.com/jobs/${job.externalId}`}>
                        {job.title}
                        {job.subtitle ? ` (${job.subtitle})` : ""}
                      </Link>
                    </TableCell>
                    <TableCell>{id}</TableCell>
                    <TableCell>{candidate.id}</TableCell>
                    <TableCell>
                      {candidate.firstName + " " + candidate.lastName}
                    </TableCell>
                    <TableCell>
                      {appliedAt &&
                        format(parseISO(appliedAt), "dd/MM/yy kk:mm")}
                    </TableCell>
                    <TableCell center>
                      <SelectField
                        value={OPTIONS.find(o => o.value === externalResponded)}
                        options={OPTIONS}
                        onChange={option => handleChange(externalId, option)}
                        isDisabled={mutationLoading}
                      />
                    </TableCell>
                  </TableRow>
                )
              )}
            </tbody>
          </Table>
        </Spacing>
        <Button
          level="secondary"
          type="button"
          onClick={() =>
            fetchMore({
              variables: {
                ...defaultVariables,
                offset: company.jobApplications.length,
              },
            })
          }
        >
          Load more
        </Button>
      </Spacing>
    </Wrapper>
  );
}

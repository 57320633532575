import { useCallback, useState } from "react";
import styled from "@xstyled/styled-components";
import {
  ApolloQueryResult,
  TypedDocumentNode,
  useMutation,
} from "@apollo/client";

import { FieldWrapper } from "../FieldWrapper";

import { IndividualBulletItem } from "./IndividualBulletItem";

import { Text } from "@otta/design";
import { splitBullets } from "@otta/shared-components";
import { useQuery } from "@toolbox/apollo";
import { Card } from "@toolbox/components/Card";
import { Textarea } from "@toolbox/components/Input/Textarea";

const StyledCard = styled(Card)`
  margin: 0;
`;
interface IItem {
  id: string;
  value?: string | null;
}

interface IMutationVariables {
  id: string;
}

interface IFieldProps<F extends string, P extends string> {
  label: string;
  parentId: string;
  itemsQuery: TypedDocumentNode<
    {
      [parent in P]:
        | {
            [field in F]: IItem[];
          }
        | null
        | undefined;
    },
    { id: string }
  >;
  fieldName: F;
  parentName: P;
  createMutation: TypedDocumentNode<unknown, { id: string; value: string }>;
  updateMutation: TypedDocumentNode<unknown, { id: string; value: string }>;
  deleteMutation: TypedDocumentNode<unknown, { id: string }>;
}

function DeleteAll({
  items,
  mutation,
  refetchAll,
}: {
  items: IItem[];
  mutation: TypedDocumentNode<unknown, IMutationVariables>;
  refetchAll: () => Promise<ApolloQueryResult<unknown>>;
}) {
  const [deleteMutation, { loading }] = useMutation(mutation);

  return (
    <span
      style={{
        cursor: "pointer",
        textDecoration: "underline",
        fontSize: "14px",
      }}
      onClick={async () => {
        if (loading) {
          return;
        }

        await Promise.all(
          items.map(item => deleteMutation({ variables: { id: item.id } }))
        );
        await refetchAll();
      }}
    >
      {loading ? "Deleting..." : "Delete all"}
    </span>
  );
}

export function BulletPointField<F extends string, P extends string>({
  label,
  fieldName,
  parentName,
  parentId,
  itemsQuery,
  createMutation,
  deleteMutation,
  updateMutation,
}: IFieldProps<F, P>): React.ReactElement {
  const [newValue, setNewValue] = useState("");

  const { data, refetch } = useQuery(itemsQuery, {
    variables: { id: parentId },
  });
  const [mutation] = useMutation(createMutation);

  const handleCreate = useCallback(async () => {
    if (newValue !== "") {
      await mutation({
        variables: { value: newValue, id: parentId },
      });
      await refetch();
      setNewValue("");
    }
  }, [mutation, newValue, parentId, refetch]);

  const handlePaste = useCallback<
    React.ClipboardEventHandler<HTMLTextAreaElement>
  >(
    async e => {
      e.preventDefault();
      const clipboardData = e.clipboardData;
      const pastedData = clipboardData.getData("text");
      const items = splitBullets(pastedData);

      for (const item of items) {
        await mutation({
          variables: { value: item, id: parentId },
        });
      }

      await refetch();
    },
    [mutation, parentId, refetch]
  );

  const items = data?.[parentName]?.[fieldName] ?? [];
  return (
    <div>
      <Text bold size={-1} style={{ display: "inline-block" }}>
        {label}
      </Text>{" "}
      (
      <DeleteAll items={items} mutation={deleteMutation} refetchAll={refetch} />
      )
      <StyledCard>
        {items.map(({ id, value }) => (
          <IndividualBulletItem
            key={id}
            id={id}
            value={value}
            updateMutation={updateMutation}
            refetchAll={refetch}
            deleteMutation={deleteMutation}
          />
        ))}
        <FieldWrapper>
          <Textarea
            margin={false}
            placeholder="New item"
            value={newValue}
            onChange={e => setNewValue(e.target.value)}
            onPaste={handlePaste}
            onBlur={handleCreate}
          />
        </FieldWrapper>
      </StyledCard>
    </div>
  );
}

import { useCallback } from "react";
import { Outlet, Route, Routes, useParams } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { CompanyPage } from "./CompanyPage";
import { CreateCompanyField } from "./CreateCompanyField";
import { JobApplications } from "./JobApplications";
import { RemapJobs } from "./RemapJobs";
import { WorkflowButtons } from "./WorkflowButtons";
import { useHasFullManageCompanyPermission } from "./useHasFullManageCompanyPermission";
import { CompanySalesModal } from "./CompanySalesModal";
import { EmployerBranding } from "./EmployerBranding";
import { RefreshLiveJobs } from "./RefreshLiveJobs";

import { ErrorText, Text } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { Loading } from "@otta/shared-components";
import { useQuery } from "@toolbox/apollo";
import { Link } from "@toolbox/components/Link";
import { CompaniesIndexDocument, CompanyWorkflowStatus } from "@toolbox/schema";
import { CurrentRoleType } from "@toolbox/utils/user";
import { ProductBubbles } from "@toolbox/components/ProductBubbles";
import { LoggedInRoute } from "@toolbox/utils/routing/LoggedInRoute";

const Sidebar = styled.div`
  background: ${palette.brand.white};
  overflow-y: auto;
  border-right: 1px solid ${palette.grayscale.shade600};
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const SidebarLink = styled.div`
  background: ${palette.brand.white};
  padding: lg;

  &:nth-of-type(2n + 1) {
    background: ${palette.grayscale.shade50};
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  grid-template-columns: 250px 1fr;
`;

interface ICompaniesIndexProps {
  role: CurrentRoleType;
  workflowStatus: CompanyWorkflowStatus;
}

function Company({
  role,
  workflowStatus,
  onComplete,
}: ICompaniesIndexProps & {
  onComplete(): void;
}) {
  const { companyId } = useParams();

  if (!companyId) {
    throw new Error("No company id field");
  }

  return (
    <>
      <CompanyPage role={role} companyId={companyId} />
      <WorkflowButtons
        workflowStatus={workflowStatus}
        onComplete={onComplete}
      />
      <Outlet />
    </>
  );
}

export function CompaniesIndex({
  role,
  workflowStatus,
}: ICompaniesIndexProps): React.ReactElement {
  const { data, loading, error, refetch } = useQuery(CompaniesIndexDocument, {
    variables: { workflowStatus: workflowStatus },
    fetchPolicy: "network-only",
  });

  /*
   * the ability for ops to do this is tied to managing approved companies
   * because that is the workflow status the new companies get when created
   */
  const hasManage = useHasFullManageCompanyPermission(
    CompanyWorkflowStatus.Approved,
    role
  );

  const handleComplete = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <Wrapper>
      <Sidebar>
        {hasManage && <CreateCompanyField />}
        {loading && <Loading />}
        {error && <ErrorText>Something went wrong fetch companies</ErrorText>}

        {(data?.internalCompanies ?? []).map(company => (
          <SidebarLink key={company.id}>
            <Text as={Link} to={company.id} bold>
              {company.name}
            </Text>
            <ProductBubbles company={company} />
          </SidebarLink>
        ))}
      </Sidebar>
      <Page>
        <Routes>
          <Route path=":companyId/remap-jobs" element={<RemapJobs />} />
          <Route
            path=":companyId/secret-eb-zone"
            element={<EmployerBranding />}
          />
          <Route
            path=":companyId/refresh-live-jobs"
            element={<RefreshLiveJobs />}
          />
          <Route
            path=":companyId/job-applications"
            element={<JobApplications />}
          />
          <Route
            path=":companyId"
            element={
              <Company
                role={role}
                workflowStatus={workflowStatus}
                onComplete={handleComplete}
              />
            }
          >
            <Route
              path="add-sales"
              element={
                <LoggedInRoute allowedRoles={[CurrentRoleType.Admin]}>
                  <CompanySalesModal />
                </LoggedInRoute>
              }
            />
          </Route>
        </Routes>
      </Page>
    </Wrapper>
  );
}

import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import { SingleValue } from "react-select";

import { useQuery } from "@toolbox/apollo";
import {
  CompanyJobOffersDocument,
  JobOfferStatus,
  UpdateUserJobOfferStatusDocument,
} from "@toolbox/schema";
import { palette, pxToRem } from "@otta/design-tokens";
import { useRequiredParams } from "@toolbox/utils/routing/RequiredParams";
import { SelectField } from "@otta/design";

interface IOption {
  label: string;
  value: string;
}

const hireRemovalDropdownOptions = [
  {
    label: "Removed",
    value: JobOfferStatus.AdminRemoved,
  },
  {
    label: "Contested",
    value: JobOfferStatus.Contested,
  },
];

const OffersWrapper = styled.div`
  padding: 40;
`;

const StyledTable = styled.table`
  padding: 10px;
`;

const StyledTableHeadRow = styled.tr`
  display: flex;
  padding: 10px 0;
  border-bottom: 2px solid black;
  width: 100%;
`;

const StyledTableRow = styled.tr`
  display: flex;
  padding: 10px 0;
  border-bottom: 2px solid ${palette.grayscale.shade400};
`;
const StyledTableCell = styled.td`
  padding: 10px;
  width: ${pxToRem(300)};
  text-align: left;
  &:first-child {
    padding-left: 0;
  }
`;
const StyledTableHeadCell = styled.th`
  padding: 10px;
  width: ${pxToRem(300)};
  text-align: left;
  &:first-child {
    padding-left: 0;
  }
`;
const StyledTableBody = styled.tbody`
  padding: 10px;
`;

const StyledLink = styled(Link)`
  color: black;
  display: block;
  margin-bottom: lg;
`;

export function CompanyJobOffers(): React.ReactElement {
  const { companyId } = useRequiredParams(["companyId"]);
  const { data: jobOffers } = useQuery(CompanyJobOffersDocument, {
    variables: {
      id: companyId,
    },
  });

  const [updateUserJobOfferStatus] = useMutation(
    UpdateUserJobOfferStatusDocument
  );

  const handleChange = (
    value: SingleValue<IOption>,
    userId: string | undefined,
    jobId: string | undefined
  ) => {
    if (userId && jobId) {
      updateUserJobOfferStatus({
        variables: {
          userId: userId,
          status: value?.value as JobOfferStatus,
          jobId: jobId,
        },
      });
    }
  };

  return (
    <OffersWrapper>
      <StyledLink to="/offers">Back to company list</StyledLink>
      <h1>Job offers for {jobOffers?.company?.name}</h1>
      {jobOffers && (
        <StyledTable>
          <thead>
            <StyledTableHeadRow>
              <StyledTableHeadCell>Job title</StyledTableHeadCell>
              <StyledTableHeadCell>User ID</StyledTableHeadCell>
              <StyledTableHeadCell>Job offer ID</StyledTableHeadCell>
              <StyledTableHeadCell>Update status</StyledTableHeadCell>
            </StyledTableHeadRow>
          </thead>
          <StyledTableBody>
            {jobOffers?.company?.companyJobOffers?.map(item => {
              return (
                <StyledTableRow key={item?.id}>
                  <StyledTableCell>{item?.job.title}</StyledTableCell>
                  <StyledTableCell>{item?.user.id}</StyledTableCell>
                  <StyledTableCell>{item?.id}</StyledTableCell>
                  <StyledTableCell>
                    <SelectField
                      aria-label="Select status"
                      inputId={`select-status-${item?.id}`}
                      data-testid="job-offer-status-select"
                      value={hireRemovalDropdownOptions.find(
                        option =>
                          (item?.status?.toUpperCase() as JobOfferStatus) ===
                          option.value
                      )}
                      options={hireRemovalDropdownOptions}
                      onChange={v => {
                        handleChange(v, item?.user.id, item?.job.id);
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </StyledTableBody>
        </StyledTable>
      )}
    </OffersWrapper>
  );
}

import { Fragment, useCallback, useState, useEffect } from "react";
import styled from "@xstyled/styled-components";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";

import { AutoSetContainer } from "../FieldLabel";
import { getHighestOrder } from "../ReorderableBulletPointField";

import { RequirementBulletItem } from "./RequirementBulletItem";

import { Text, Card, Spacing } from "@otta/design";
import { splitBullets } from "@otta/shared-components";
import { Textarea } from "@toolbox/components/Input/Textarea";

const ColumnHeader = styled(Text)`
  grid-column-start: 2;
`;

const StyledCard = styled(Card)`
  margin: 0;
`;

const NewRequirementWrapper = styled.div`
  padding: 0 54 0 23;
`;
interface IItem {
  id: string;
  value: string;
  desirable: boolean;
  order: number | null | undefined;
}

interface IFieldProps {
  label: string;
  parentId: string;
  data: IItem[];
  handleUpdate: (
    field: {
      value: string | null;
      order: number | null;
      desirable: boolean | null;
    }[]
  ) => Promise<void>;
  autoSet?: boolean;
}

export function JobRequirementsField({
  label,
  data,
  handleUpdate,
  parentId,
  autoSet,
}: IFieldProps): React.ReactElement {
  const [newValue, setNewValue] = useState("");
  const [items, setItems] = useState<IItem[]>(data);

  useEffect(() => setItems(data), [data]);

  const updateItems = async (newItems: IItem[]) => {
    await handleUpdate(
      newItems
        .filter(({ value, order }) => value && typeof order === "number")
        .map(
          ({
            value,
            order,
            desirable,
          }): { value: string; order: number; desirable: boolean } => ({
            value: value as string,
            order: order as number,
            desirable: desirable as boolean,
          })
        )
    );
    setItems(newItems);
  };

  const onCreate = () => {
    if (newValue !== "") {
      const highestOrder = getHighestOrder(items);

      updateItems([
        ...items,
        {
          value: newValue,
          order: highestOrder + 1,
          desirable: false,
          id: parentId,
        },
      ]);

      setNewValue("");
    }
  };

  const onUpdate = ({
    id,
    value,
    order,
    desirable,
  }: {
    id: string;
    value: string;
    order: number;
    desirable: boolean;
  }) => {
    updateItems(
      items.map(item => {
        if (item.id === id) {
          return { ...item, value, order, desirable };
        }

        return item;
      })
    );
  };

  const onPaste = useCallback<React.ClipboardEventHandler<HTMLTextAreaElement>>(
    e => {
      e.preventDefault();

      const pastedData = e.clipboardData.getData("text");

      const pastedItems = splitBullets(pastedData);

      const deduplicated = pastedItems.filter((item, index) => {
        return index === pastedItems.indexOf(item);
      });

      const newItems = [...items];
      const numItems = items.length;
      let count = numItems + 1;

      for (const item of deduplicated) {
        newItems.push({
          value: item,
          order: count,
          desirable: false,
          id: parentId,
        });
        count = count + 1;
      }

      updateItems(newItems);
    },
    [parentId, items]
  );

  const onDelete = (id: string) => {
    updateItems(items.filter(item => item.id !== id));
  };

  const reorder = (list: IItem[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    const updatedResult: IItem[] = result.map((item, index) => ({
      value: item.value as string,
      order: index + 1,
      desirable: item.desirable,
      id: item.id,
    }));

    return updatedResult;
  };

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      updateItems(
        reorder(items, result.source.index, result.destination.index)
      );
    },
    [items]
  );

  return (
    <div>
      <Text bold size={-1} style={{ display: "inline-block" }}>
        {label}
      </Text>{" "}
      {autoSet && <AutoSetContainer>Auto-set</AutoSetContainer>}
      <Text
        as="span"
        style={{
          cursor: "pointer",
          textDecoration: "underline",
          fontSize: "14px",
        }}
        data-testid="delete-all-button"
        onClick={() => updateItems([])}
      >
        (Delete all)
      </Text>
      <StyledCard>
        <Spacing>
          {items.length > 0 && (
            <ColumnHeader align="right" size={-1}>
              Desirable
            </ColumnHeader>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <div
                  data-testid={"reorderable-bullets"}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {items.map((item, index) => (
                    <Draggable
                      key={index}
                      draggableId={index.toString()}
                      index={index}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Fragment key={item.id}>
                            <RequirementBulletItem
                              id={item.id}
                              value={item.value}
                              desirable={item.desirable}
                              order={item?.order ?? 1}
                              handleUpdate={onUpdate}
                              handleDelete={onDelete}
                            />
                          </Fragment>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <NewRequirementWrapper>
            <Textarea
              margin={false}
              placeholder="New item"
              data-testid="new-item-field"
              value={newValue}
              onChange={e => setNewValue(e.target.value)}
              onPaste={onPaste}
              onBlur={onCreate}
            />
          </NewRequirementWrapper>
        </Spacing>
      </StyledCard>
    </div>
  );
}

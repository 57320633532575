import { useMutation } from "@apollo/client";
import { format, parseISO } from "date-fns";
import { useCallback } from "react";
import { Field, Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import styled, { css } from "@xstyled/styled-components";

import { Button, Checkbox, Spacing, Text } from "@otta/design";
import { modularScale } from "@otta/design-tokens";
import { Loading } from "@otta/shared-components";
import { useQuery } from "@toolbox/apollo";
import { Link } from "@toolbox/components/Link";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "@toolbox/components/Table";
import {
  CompanyRemapJobsDocument,
  RemapCompanyJobsDocument,
} from "@toolbox/schema";
import { handleMutationError } from "@toolbox/utils/error";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  padding: lg;
  padding-top: 0;
`;

const StyledTableRow = styled(TableRow)<{ live?: boolean | null }>`
  ${({ live }) =>
    live &&
    css`
      background-color: green-200 !important;
    `}
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const Input = styled.input`
  width: 100%;
  font-size: ${modularScale(-1)};
`;

interface FormData {
  id: string;
  scraperUrl?: string | null;
  moveToInternal: boolean;
  jobs: { id: string; originalUrl?: string | null; checked: boolean }[];
}

export function RemapJobs(): React.ReactElement {
  const { companyId } = useParams();
  const navigate = useNavigate();

  const defaultVariables = {
    id: companyId as string,
    limit: 50,
    offset: 0,
  };

  const { data, fetchMore } = useQuery(CompanyRemapJobsDocument, {
    variables: defaultVariables,
  });

  const [remapCompanyJobs, { loading: mutationLoading }] = useMutation(
    RemapCompanyJobsDocument,
    {
      onError: handleMutationError,
      update: (cache, mutationResult) => {
        const company = mutationResult?.data?.remapCompanyJobs;
        const cacheData = cache.readQuery({
          query: CompanyRemapJobsDocument,
          variables: { id: companyId as string },
        });

        if (!company || !cacheData) {
          return null;
        }

        cache.writeQuery({
          query: CompanyRemapJobsDocument,
          variables: { id: companyId as string },
          data: {
            company: {
              ...company,
            },
          },
        });
      },
    }
  );

  const company = data?.company;

  const handleSubmit = useCallback(
    ({ id, scraperUrl, moveToInternal, jobs }: FormData) => {
      const filteredJobs = jobs
        .filter(j => j.checked)
        .map(j => ({ id: j.id, originalUrl: j.originalUrl }));

      if (
        window.confirm(
          `It looks like you're trying to move to an ${
            moveToInternal ? "INTERNAL" : "EXTERNAL"
          } job board and have decided to keep ${
            filteredJobs.length
          } jobs. Are you sure? All unselected jobs in COMPLETED, CHANGED, INACTIVE, and RECRUITER_DRAFT will be archived`
        )
      ) {
        remapCompanyJobs({
          variables: {
            id,
            moveToInternal,
            jobs: filteredJobs,
            scraperUrl: (scraperUrl || company?.scraperUrl) as string,
          },
        });
      }
    },
    [remapCompanyJobs, company]
  );

  if (!company || mutationLoading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <Form<FormData>
        onSubmit={handleSubmit}
        initialValues={{
          id: company.id,
          scraperUrl: company.scraperUrl,
          moveToInternal: false,
          jobs: company.listJobs.map(({ id, actualOriginalUrl }) => ({
            id,
            originalUrl: actualOriginalUrl,
            checked: false,
          })),
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Spacing>
              <Text bold size={1}>
                {company.name}
              </Text>
              <Field name="moveToInternal" subscription={{ value: true }}>
                {({ input: { value: moveToInternal } }) => (
                  <Spacing size={-2}>
                    {company.scraperUrl && (
                      <Field name="moveToInternal" type="checkbox">
                        {({ input }) => {
                          return (
                            <Checkbox label="Move to internal" {...input} />
                          );
                        }}
                      </Field>
                    )}
                    <Text bold>Scraper URL</Text>
                    <Field name="scraperUrl">
                      {({ input }) => (
                        <Input
                          type="url"
                          required
                          disabled={moveToInternal}
                          {...input}
                        />
                      )}
                    </Field>
                    <Text bold>
                      All jobs in completed, changed, inactive, or archived
                    </Text>
                    <Table>
                      <thead>
                        <StyledTableRow>
                          <TableHeader />
                          <TableHeader>Title (subtitle)</TableHeader>
                          <TableHeader>Workflow status</TableHeader>
                          <TableHeader>Inserted at (UTC)</TableHeader>
                          <TableHeader>Last seen (UTC)</TableHeader>
                          <TableHeader>Current URL</TableHeader>
                          <TableHeader>Keep</TableHeader>
                          <TableHeader>New URL</TableHeader>
                        </StyledTableRow>
                      </thead>
                      <tbody>
                        {company.listJobs.map(
                          (
                            {
                              id,
                              externalId,
                              editedTitle,
                              subtitle,
                              live,
                              workflowStatus,
                              insertedAt,
                              lastSeen,
                              actualOriginalUrl,
                            },
                            index
                          ) => (
                            <StyledTableRow key={id} live={live}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                <Link
                                  to={`https://app.otta.com/jobs/${externalId}`}
                                >
                                  {editedTitle}
                                  {subtitle ? ` (${subtitle})` : ""}
                                </Link>
                              </TableCell>
                              <TableCell>{workflowStatus}</TableCell>
                              <TableCell>
                                {format(parseISO(insertedAt), "dd/MM/yy kk:mm")}
                              </TableCell>
                              <TableCell>
                                {lastSeen &&
                                  format(parseISO(lastSeen), "dd/MM/yy kk:mm")}
                              </TableCell>
                              <TableCell>
                                {actualOriginalUrl ? (
                                  <Link to={actualOriginalUrl}>
                                    {actualOriginalUrl}
                                  </Link>
                                ) : null}
                              </TableCell>
                              <Field
                                name={`jobs[${index}].checked`}
                                subscription={{ value: true }}
                              >
                                {({ input: { value: jobSelected } }) => (
                                  <>
                                    <TableCell center>
                                      <Field
                                        name={`jobs[${index}].checked`}
                                        type="checkbox"
                                      >
                                        {({ input }) => {
                                          return (
                                            <input
                                              {...input}
                                              data-testid="keep-checkbox"
                                            />
                                          );
                                        }}
                                      </Field>
                                    </TableCell>
                                    <TableCell fullWidth>
                                      <Field
                                        name={`jobs[${index}].originalUrl`}
                                      >
                                        {({ input }) => {
                                          return (
                                            <Input
                                              {...input}
                                              type="url"
                                              required
                                              disabled={
                                                !jobSelected || moveToInternal
                                              }
                                            />
                                          );
                                        }}
                                      </Field>
                                    </TableCell>
                                  </>
                                )}
                              </Field>
                            </StyledTableRow>
                          )
                        )}
                      </tbody>
                    </Table>
                  </Spacing>
                )}
              </Field>
              <Button
                level="secondary"
                type="button"
                onClick={() =>
                  fetchMore({
                    variables: {
                      ...defaultVariables,
                      offset: company.listJobs.length,
                    },
                  })
                }
              >
                Load more
              </Button>
              <ButtonContainer>
                <Button
                  level="primary"
                  type="submit"
                  style={{ marginRight: 10 }}
                >
                  Submit
                </Button>
                <Button
                  type="button"
                  level="secondary"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </ButtonContainer>
            </Spacing>
          </form>
        )}
      </Form>
    </Wrapper>
  );
}

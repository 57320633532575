import styled from "@xstyled/styled-components";

import { floatUp, pressDown } from "@toolbox/components/Animations";
import { palette, pxToRem, modularScale } from "@otta/design-tokens";

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  level: string;
  colour?: string;
  disabled?: boolean;
}

export const StyledButton = styled.button<IButtonProps>`
  min-width: 64px;
  background-color: ${({ theme, level, colour, disabled = false }) =>
    disabled
      ? palette.brand.grey
      : colour
      ? theme.palette[colour].medium
      : theme.button[level].background};
  border: ${({ level }) => (level === "secondary" ? "solid" : "none")};
  color: ${({ theme, level, disabled }) =>
    disabled ? palette.grayscale.shade400 : theme.button[level].colour};
  padding: md xxl;
  text-align: center;
  font-size: ${modularScale()};
  font-weight: 700;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border-radius: ${pxToRem(100)};
  border: ${({ disabled }) => (disabled ? "1px solid" : "none")};
  border-color: gray-200;

  &:hover {
    animation: ${floatUp} 0.5s ease-out 1 forwards;
  }

  &:active {
    animation: ${pressDown} 0.2s ease-out 1 forwards;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  > * {
    margin: 8px 8px;
  }
`;

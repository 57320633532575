import { useCallback, useState } from "react";
import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";

import { BenefitLocationField } from "./BenefitLocationField";
import { BenefitTextField } from "./BenefitTextField";

import { pxToRem } from "@otta/design-tokens";
import { Text } from "@otta/design";
import { Loading, splitBullets } from "@otta/shared-components";
import { handleMutationError } from "@toolbox/utils/error";
import { useQuery } from "@toolbox/apollo";
import { Card } from "@toolbox/components/Card";
import { Textarea } from "@toolbox/components/Input/Textarea";
import {
  CompanyOtherBenefitsDocument,
  CreateCompanyOtherBenefitDocument,
  DeleteCompanyOtherBenefitDocument,
  CompanyOtherBenefits,
} from "@toolbox/schema";

const ColumnHeader = styled.div`
  padding-top: 5;
  margin-top: 0;
  padding-bottom: 20;
  margin-right: 20;
  display: flex;
  flex-direction: row-reverse;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  div:first-child {
    flex: 1;
  }
`;

const StyledCard = styled(Card)`
  margin: 0;
`;

const NewFieldWrapper = styled.div`
  max-width: ${pxToRem(390)};
  position: relative;
  margin-left: 0;
`;
interface IFieldProps {
  label: string;
  parentId: string;
}

function DeleteAll({
  benefits,
  refetch,
}: {
  benefits: CompanyOtherBenefits.OtherBenefits[];
  refetch: () => Promise<any>;
}) {
  const [deleteMutation, { loading }] = useMutation(
    DeleteCompanyOtherBenefitDocument,
    {
      onError: handleMutationError,
    }
  );

  async function deleteAll() {
    if (loading) {
      return;
    }

    await Promise.all(
      benefits.map(b => deleteMutation({ variables: { id: b.id } }))
    );
    await refetch();
  }

  return (
    <span
      style={{
        cursor: "pointer",
        textDecoration: "underline",
        fontSize: "14px",
      }}
      onClick={deleteAll}
    >
      ({loading ? "Deleting..." : "Delete all"})
    </span>
  );
}

export function CompanyBenefitsField({
  label,
  parentId,
}: IFieldProps): React.ReactElement {
  const [newValue, setNewValue] = useState("");
  const [processingCreate, setProcessingCreate] = useState(false);

  const { data, refetch, loading } = useQuery(CompanyOtherBenefitsDocument, {
    variables: { id: parentId },
    fetchPolicy: "network-only",
  });
  const [mutation] = useMutation(CreateCompanyOtherBenefitDocument, {
    onError: handleMutationError,
  });

  const createNewBenefit = useCallback(
    async (value: string) => {
      if (value.length) {
        await mutation({
          variables: { value, id: parentId },
        });
      }
    },
    [mutation, parentId]
  );

  const handlePaste = useCallback<
    React.ClipboardEventHandler<HTMLTextAreaElement>
  >(
    async e => {
      e.preventDefault();
      const clipboardData = e.clipboardData;
      const pastedData = clipboardData.getData("text");
      const bullets = splitBullets(pastedData);

      if (bullets.length) {
        setProcessingCreate(true);
        for (const value of bullets) {
          await createNewBenefit(value);
        }
        await refetch();
        setProcessingCreate(false);
      }
    },
    [createNewBenefit, refetch]
  );

  const handleBlur = useCallback(async () => {
    setProcessingCreate(true);
    await createNewBenefit(newValue);
    setNewValue("");
    await refetch();
    setProcessingCreate(false);
  }, [createNewBenefit, newValue, refetch]);

  const benefits = data?.company?.otherBenefits ?? [];

  return (
    <>
      <Text bold size={-1} style={{ display: "inline-block" }}>
        {label}
      </Text>
      <DeleteAll benefits={benefits} refetch={refetch} />
      <StyledCard>
        {benefits.length > 0 && <ColumnHeader>Location</ColumnHeader>}
        {benefits.map((benefit, index) => (
          <RowWrapper key={benefit.id}>
            <BenefitTextField
              id={benefit.id}
              value={benefit.value ?? ""}
              refetchAll={refetch}
              data-testid="benefit-text-field"
            />
            <BenefitLocationField
              id={benefit.id}
              location={benefit.location}
              index={index}
              data-testid="benefit-location-field"
            />
          </RowWrapper>
        ))}
        {(loading || processingCreate) && <Loading />}
        <NewFieldWrapper>
          <Textarea
            disabled={loading}
            margin={false}
            placeholder="New benefit"
            value={newValue}
            onChange={e => setNewValue(e.target.value)}
            onPaste={handlePaste}
            onBlur={handleBlur}
          />
        </NewFieldWrapper>
      </StyledCard>
    </>
  );
}

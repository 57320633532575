import { DocumentNode } from "graphql";
import styled from "@xstyled/styled-components";
import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { FieldWrapper } from "./FieldWrapper";

import { Input, Label } from "@toolbox/components/Input";

const ValueWrapper = styled.div`
  padding: 24px 0 0;
`;

interface IUploadFieldProps<V> {
  label: string;
  value: V | null;
  mutation: DocumentNode;
  id: string;
  accept: string[];
  display: (v: V) => React.ReactNode;
}

export function UploadField<V>({
  id,
  label,
  value,
  accept,
  display,
  mutation,
}: IUploadFieldProps<V>): React.ReactElement {
  const [uploadMutation] = useMutation(mutation, {
    onError(e) {
      window.alert(e);
    },
  });

  const handleUpload = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async ({ target: { files } }) => {
      if (files?.length === 1) {
        const file = files[0];
        await uploadMutation({ variables: { file, id } });
      }
    },
    [id, uploadMutation]
  );

  return (
    <FieldWrapper>
      <Label>{label}</Label>
      {value && <ValueWrapper>{display(value)}</ValueWrapper>}
      <Input type="file" accept={accept.join(", ")} onChange={handleUpload} />
    </FieldWrapper>
  );
}

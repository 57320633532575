import styled from "@xstyled/styled-components";
import { useMemo } from "react";

import { JobStatuses } from "./PaginatedTable";

import { palette } from "@otta/design-tokens";
import { Checkbox } from "@toolbox/components/Input/Checkbox";
import { JobsList, JobWorkflowStatus } from "@toolbox/schema";

const HeaderContainer = styled.thead``;

const Row = styled.tr``;

const HeaderCell = styled.th`
  padding: xs;
  font-weight: bold;
  text-align: left;
`;

const SelectionCell = styled(HeaderCell)`
  border-left: 1px solid ${palette.grayscale.shade400};
`;

interface HeaderRowProps {
  jobs: JobsList.Jobs[];
  unsavedChanges: JobStatuses;
  handleToggle(payload: {
    ids: string[];
    workflowStatus: JobWorkflowStatus;
  }): void;
}

export const HeaderRow = ({
  jobs,
  unsavedChanges,
  handleToggle,
}: HeaderRowProps) => {
  const allDisapproved = useMemo(
    () =>
      jobs.every(
        j => unsavedChanges.get(j.id) === JobWorkflowStatus.Disapproved
      ),
    [jobs, unsavedChanges]
  );

  const allApproved = useMemo(
    () =>
      jobs.every(
        j => unsavedChanges.get(j.id) === JobWorkflowStatus.QueuedExternal
      ),
    [jobs, unsavedChanges]
  );

  const toggleAll = (workflowStatus: JobWorkflowStatus) => {
    handleToggle({
      ids: jobs.map(job => job.id),
      workflowStatus,
    });
  };

  return (
    <HeaderContainer>
      <Row>
        <HeaderCell>Company Name</HeaderCell>
        <HeaderCell>Job Title</HeaderCell>
        <HeaderCell>Sub Function</HeaderCell>
        <HeaderCell>Original URL</HeaderCell>
        <HeaderCell>Scraped Location</HeaderCell>
        <HeaderCell>Location Preferences</HeaderCell>
        <SelectionCell>
          Disapprove
          <Checkbox
            data-testid="disapprove-all"
            label=""
            checked={allDisapproved}
            onChange={() => {
              toggleAll(JobWorkflowStatus.Disapproved);
            }}
          />
        </SelectionCell>
        <SelectionCell>
          Queue
          <Checkbox
            data-testid="queue-all"
            label=""
            checked={allApproved}
            onChange={() => {
              toggleAll(JobWorkflowStatus.QueuedExternal);
            }}
          />
        </SelectionCell>
      </Row>
    </HeaderContainer>
  );
};

import { Route, Routes } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { AllUsers } from "./AllUsers";
import { JobOffers } from "./JobOffers";

import { Text } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { Link } from "@toolbox/components/Link";

const Subtitles = styled.div`
  display: flex;
  padding: lg;
  gap: lg;
  border-bottom: 1px solid ${palette.brand.black};
`;

export default function Users(): React.ReactElement {
  return (
    <>
      <Subtitles>
        <Text as={Link} to=".">
          Users
        </Text>
        <Text as={Link} to="offers">
          Offers
        </Text>
      </Subtitles>

      <Routes>
        <Route path="/" element={<AllUsers />} />
        <Route path="offers" element={<JobOffers />} />
      </Routes>
    </>
  );
}
